.max-w-95{
 max-width: 95%!important;
}
.prices{
  margin: 50px;
  .b2b, .d2c{
    .title{
      padding: 20px;
    }
    .price{
      padding: 20px;
    }

  }
  button{
    margin: 20px;
  }
}
